/* eslint-disable quotes */
'use strict';

/**
* Push to window.gtm array
*
* @param {Object} currentGtmData gtm data.
*/
function pushToDataLayer(currentGtmData) {
    if (typeof currentGtmData === 'string') {
        var tempGtmData = JSON.parse(currentGtmData);

        if (tempGtmData.length > 0) {
            for (var i = 0; i < tempGtmData.length; i++) {
                window.dataLayer.push(JSON.parse(tempGtmData[i]));
            }
        } else {
            window.dataLayer.push(JSON.parse(tempGtmData));
        }
    } else if (currentGtmData && currentGtmData.length > 0) {
        for (var x = 0; x < currentGtmData.length; x++) {
            window.dataLayer.push(JSON.parse(currentGtmData[x]));
        }
    } else {
        window.dataLayer.push(JSON.parse(currentGtmData));
    }
}


/**
* Parse search query string
*
* @param {Object} search search query string.
* @return {Object} parsed query string
*/
function parseQuery(search) {
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
    });
}

/**
* Gets analytics data from data-analytics attribute of element
*
* @param {JQuery} container with data analytics attribute
* @return {Object|null} analytics data
*/
function getAnalytics(container) {
    const dataAnalytics = container ? container.data('analytics') : null;
    return dataAnalytics;
}

/**
* Sends impression view event
*
* @param {Object} event object
* @param {Object} data of event
*/
function sendImpressionView(event, data) {
    if (!data.container || !data.container.length) {
        return;
    }

    const $eventDataContainer = data.container.find('.js-gtm-event_data');

    if (!$eventDataContainer.length) {
        return;
    }

    const dataAnalytics = getAnalytics($eventDataContainer);

    if (!dataAnalytics) {
        return;
    }

    let products = [];
    const MAX_IMPRESSIONS_PER_REQUEST = 16;

    data.container.find('.product-tile').each(function (index) {
        let analytics = getAnalytics($(this));
        if (analytics) {
            analytics.index = index + 1;
            if (dataAnalytics.category) {
                analytics.item_list_name = dataAnalytics.categoryName; // 'Category List - ' + dataAnalytics.category.replaceAll('|', ' > ');
                analytics.item_list_id = dataAnalytics.catID;
                analytics.item_brand = 'IRO';
                analytics.quantity = '1';
                analytics.item_category3 = dataAnalytics.categoryName;
                analytics.price = $(this).find('span.sales').attr('content');
                $(this).data('list-type', analytics.list);
            }
            products.push(analytics);
        }
    });

    for (let i = 0; i < products.length; i += MAX_IMPRESSIONS_PER_REQUEST) {
        let eventObj = {
            event: dataAnalytics.event,
            // eventCategory: 'Ecommerce',
            // eventAction: 'Impression',
            // eventLabel: dataAnalytics.eventLabel,
            ecommerce: {
                // currencyCode: dataAnalytics.currencyCode,
                items: products.slice(i, i + MAX_IMPRESSIONS_PER_REQUEST)
            }
            // filters: dataAnalytics.filters,
            // page_number: dataAnalytics.page_number
        };

        window.dataLayer.push(eventObj);
    }
}

module.exports = function () {
    if (typeof (window.dataLayer) === 'undefined') {
        window.dataLayer = [];
    }

    if (typeof (window.gtmData) === 'undefined') {
        window.gtmData = [];
    }

    var gtmData = window.gtmData;
    for (var i = 0; i < gtmData.length; i++) {
        var tempGtmData = JSON.parse(gtmData[i]);
        // If the rootcause of page key is found for EU website, this code could be removed
        if (tempGtmData.page && tempGtmData.page.event === 'datalayer_ready') {
            window.dataLayer.push(tempGtmData.page);
        } else {
            window.dataLayer.push(tempGtmData);
        }
    }

    window.onPromoClick = function (id, name, creative, position) {
        var promoObj = {
            event: 'promoClick',
            ecommerce: {
                promoClick: {
                    promotions: [{
                        id: id, // Name or ID is required.
                        name: name,
                        creative: creative,
                        position: position
                    }]
                }
            }
        };
        window.dataLayer.push(promoObj);
    };


    if ($('div.promoView').length) {
        $('.promoView').each(function () {
            window.onPromoClick($(this).data('promoid'), $(this).data('promoname'), $(this).data('promocreative'), $(this).data('promoposition'));
        });
    }

    // $('body').on('stagechange', function (e, stageNumber) {
    //     var basket = JSON.parse(window.gtmBasket);

    //     // if (stageNumber === 1) {
    //     //     var selectedShippingMethod = $('input[name=dwfrm_shipping_shippingAddress_shippingMethodID]:checked:visible').next('label').find('.display-name').text();
    //     //     var country = $('#shippingCountrydefault option:selected').text();
    //     //     window.dataLayer.push({
    //     //         event: 'eec.checkout_option',
    //     //         ecommerce: {
    //     //             checkout: {
    //     //                 actionField: {
    //     //                     step: 3,
    //     //                     option: selectedShippingMethod,
    //     //                     country: country
    //     //                 }
    //     //             }
    //     //         }
    //     //     });

    //     //     window.dataLayer.push({
    //     //         event: 'add_shipping_info',
    //     //         ecommerce: {
    //     //             checkout: {
    //     //                 products: basket.ecommerce.checkout.products,
    //     //                 actionField: {
    //     //                     step: 4
    //     //                 },
    //     //                 view: 1
    //     //             }
    //     //         }
    //     //     });
    //     // }
    //     // if (stageNumber === 2) {
    //     //     var selectedPaymentMethod = $('.js-collapse.collapsible-xl.active .nav-item').data('method-id');
    //     //     var selectedPayment = '';
    //     //     if (selectedPaymentMethod === 'CREDIT_CARD') {
    //     //         selectedPayment = 'Credit Card';
    //     //     } else if (selectedPaymentMethod === 'PayPal') {
    //     //         selectedPayment = 'PayPal';
    //     //     }
    //     //     window.dataLayer.push({
    //     //         event: 'eec.checkout_option',
    //     //         ecommerce: {
    //     //             checkout: {
    //     //                 actionField: {
    //     //                     step: 4,
    //     //                     option: selectedPayment
    //     //                 }
    //     //             }
    //     //         }
    //     //     });
    //     //     window.dataLayer.push({
    //     //         event: 'eec.checkout',
    //     //         ecommerce: {
    //     //             checkout: {
    //     //                 products: basket.ecommerce.checkout.products,
    //     //                 actionField: {
    //     //                     step: 5
    //     //                 },
    //     //                 view: 1
    //     //             }
    //     //         }
    //     //     });
    //     // }
    //     // if (stageNumber === 3) {
    //     //     window.dataLayer.push({
    //     //         event: 'eec.checkout',
    //     //         ecommerce: {
    //     //             checkout: {
    //     //                 actionField: {
    //     //                     step: 6
    //     //                 }
    //     //             }
    //     //         }
    //     //     });
    //     // }
    // });


    if ($('div.gtm-content-asset').length) {
        $(window).on('load', function () {
            window.dataLayer.push({
                event: $('div.gtm-content-asset').data('event')

            });
        });
    }

    if ('.gtm-pd-event'.length) {
        var skeletonData = {
            event: 'view_promotion',
            ecommerce: {
                items: []
            }
        };

        $(window).on('load', function () {
            var containers = $('.gtm-pd-event');
            containers.each(function (index, el) {
                var data = $(el).data('gtm-pd-event');
                if (data) {
                    skeletonData.ecommerce.items.push($(el).data('gtm-pd-event'));
                }

                // Add click event handler to trigger the event for the clicked element
                $(el).on('click', function (e) {
                    e.preventDefault();
                    var target = $(e.target);

                    var clickData = {
                        event: 'select_promotion',
                        ecommerce: {
                            items: [$(el).data('gtm-pd-event')]
                        }
                    };

                    window.dataLayer.push(clickData);
                    var anchor = target.is('a') ? target : target.closest('a');

                    if (anchor.length > 0) {
                        setTimeout(function () {
                            window.location.href = $(anchor).attr('href');
                        }, 500);
                    }
                });
            });
            if (skeletonData.ecommerce.items.length > 0) {
                window.dataLayer.push(skeletonData);
            }
        });
    }

    $('body').on('product:beforeAddToCart', function () {
        $(document).bind('ajaxSend', function (event, jqxhr, settings) {
            if ($('.product-set-detail').length) {
                var productSetId = $('.product-set-detail').data('pid');

                settings.data += '&productSetId=' + productSetId; // eslint-disable-line no-param-reassign
            }
        });
    });

    $('body').on('product:afterAddToCart', function (event, data) {
        try {
            pushToDataLayer(data.gtmData);
        } catch (e) {
            // Fail silently if data cannot be pushed to data layer
        }
    });


    var gtmPlpRefine = '#ajaxGtmType=refine';
    var gtmPlpShowMore = '#ajaxGtmType=showmore';
    var cartUpdateQuantity = 'UpdateQuantity';
    var cartRemoveProduct = 'RemoveProductLineItem';
    var addToCartSet = 'productSetId';
    $(document).bind('ajaxComplete', function (event, jqxhr, settings) {
        if (settings.url.toLowerCase().indexOf(gtmPlpRefine.toLowerCase()) !== -1 || settings.url.toLowerCase().indexOf(gtmPlpShowMore.toLowerCase()) !== -1) {
            try {
                pushToDataLayer(window.tempGtmData);
            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }

            $(document).unbind('ajaxSend');

            settings.url.replace(gtmPlpRefine, '');

            settings.url.replace(gtmPlpShowMore, '');

            if (window.yotpo) {
                window.yotpo.refreshWidgets();
            }

            if ($('.lazy').length && jQuery.lazy) {
                $('.lazy').lazy('update');
            }
        }
        if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1) {
            var search = jqxhr.responseJSON.queryString;

            var queryObject = parseQuery(search);

            var $originalE = $('div.quantity-form').find("[data-pid='" + queryObject.pid + "']").parent();

            $originalE.data('currentquantity', queryObject.quantity);

            try {
                pushToDataLayer(jqxhr.responseJSON.gtmData);
            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }
        }
        if (settings.url.toLowerCase().indexOf(cartRemoveProduct.toLowerCase()) !== -1) {
            try {
                pushToDataLayer(jqxhr.responseJSON.gtmData);
            } catch (e) {

                // Fail silently if data cannot be pushed to data layer

            }
        }
        if (settings.data != null) {
            if (settings.data.toLowerCase().indexOf(addToCartSet.toLowerCase()) !== -1) {
                $(document).unbind('ajaxSend');
            }
        }

        try {
            if (jqxhr.responseJSON.gtmData) {
                let parsedArray;
                try {
                    parsedArray = JSON.parse(jqxhr.responseJSON.gtmData);
                } catch (e) {
                    // Keep the old behavior
                    pushToDataLayer(jqxhr.responseJSON.gtmData);
                    return;
                }

                if (Array.isArray(parsedArray) && parsedArray[0]) {
                    let eventData;

                    try {
                        eventData = JSON.parse(parsedArray[0]);
                    } catch (e) {
                        // Keep the old behavior
                        pushToDataLayer(jqxhr.responseJSON.gtmData);
                        return;
                    }

                    // If the event is add_to_cart we don't need to push it because it's pushed in the product:afterAddToCart
                    // If the requirement changes to fire add_to_cart independatly of performed successful add_to_cart
                    // then the pushToDataLayer in product:afterAddToCart and below condition should be removed
                    // Remove_from_cart is analogous to add_to_cart
                    if (eventData.event !== 'add_to_cart' && eventData.event !== 'remove_from_cart') {
                        pushToDataLayer(jqxhr.responseJSON.gtmData);
                    }
                }
            }
        } catch (e) {

            // Fail silently if data cannot be pushed to data layer
        }
    });


    $(document).bind('ajaxSend', function (event, jqxhr, settings) {
        if (settings.url.toLowerCase().indexOf(cartUpdateQuantity.toLowerCase()) !== -1 || settings.url.toLowerCase().indexOf(cartRemoveProduct.toLowerCase()) !== -1) {
            var searchHr = settings.url;
            var search = searchHr.substr(searchHr.indexOf('?') + 1);
            var queryObject = parseQuery(search);
            var $originalQ = $('body').find("select[data-pid='" + queryObject.pid + "']");
            settings.url += '&originalq=' + parseInt($originalQ.data('pre-select-qty'), 10); // eslint-disable-line no-param-reassign
        }
    });


    $('body').on('search:filter', function () {
        $(document).bind('ajaxSend', function (event, jqxhr, settings) {
            if (settings.url.toLowerCase().indexOf(gtmPlpRefine.toLowerCase()) === -1) {
                settings.url += gtmPlpRefine; // eslint-disable-line no-param-reassign
            }
        });
    });

    $('body').on('search:initialized', sendImpressionView);
    $('body').on('search:updated', sendImpressionView);

    $('body').on('search:showMore', function () {
        $(document).bind('ajaxSend', function (event, jqxhr, settings) {
            if (settings.url.toLowerCase().indexOf(gtmPlpShowMore.toLowerCase()) === -1) {
                settings.url += gtmPlpShowMore; // eslint-disable-line no-param-reassign
            }
        });
    });

    if ($('.product-grid').length) {
        $('body').trigger('search:initialized', { container: $('.product-grid') });
    }

    // if ($('.product-recommendations, .carousel, .slick-slider').length > 0) {
    //     var listType = 'Recommendations';
    //     var impressions = [];
    //     $('.product-recommendations .product, .carousel .product, .slick-slider .product').each(function () {
    //         if ($(this).parents('#homepage').length > 0) {
    //             listType = 'Home - Recommended';
    //         } else if ($(this).parents('[data-action="Product-Show"]').length > 0) {
    //             listType = 'Product Recommendations - ' + $('.product-info .product-name').text();
    //         }
    //         $(this).data('list-type', listType);
    //         $(this).find('.product-tile').data('list-type', listType);
    //         impressions.push({
    //             name: $(this).find('.product-name__link, .product-name-link a').text(),
    //             id: String($(this).data('pid')),
    //             price: String($(this).find('span.sales').attr('content') || $(this).find('span.sales .value').attr('content')),
    //             category: String($(this).find('.product-tile').data('category') || $(this).data('category')),
    //             variant: String($(this).find('.product-tile').data('variation') || $(this).data('variation')),
    //             list: listType,
    //             position: $(this).find('.product-tile').data('positionlist') || $(this).parent('.carousel-item').data('position') + 1

    //         });
    //     });

    //     var currentGtmData = {
    //         event: 'eec.impressionView',
    //         ecommerce: {
    //             items: impressions
    //         }
    //     };

    //     if (window.dataLayer && $('.product-recommendations, .carousel, .slick-slider').parents('[data-action="Product-Show"]').length > 0) {
    //         for (let index = 0; index < window.dataLayer.length; index++) {
    //             let dataLayerItem = window.dataLayer[index];
    //             if (dataLayerItem.productPage) {
    //                 currentGtmData.ecommerce.detail = dataLayerItem.productPage.ecommerce.detail;
    //                 currentGtmData.ecommerce.currencyCode = dataLayerItem.productPage.ecommerce.currencyCode;
    //             }
    //         }
    //     }

    //     window.dataLayer.push(currentGtmData);
    // }

    $('body').on('click', 'div.product-tile, .product-tile-pd', function () {
        var items = [];
        var positionInList = $(this).data('positionlist') || $(this).parent('.carousel-item').data('position') + 1;
        const $eventDataContainer = $('body').find('.js-gtm-event_data');
        const dataAnalytics = getAnalytics($eventDataContainer);
        let analytics = getAnalytics($(this));
        if (analytics) {
            analytics.index = positionInList || 1;
            if (dataAnalytics.category) {
                analytics.item_list_name = dataAnalytics.categoryName; // 'Category List - ' + dataAnalytics.category.replaceAll('|', ' > ');
                analytics.item_list_id = dataAnalytics.catID;
                analytics.item_brand = 'IRO';
                analytics.quantity = '1';
                analytics.item_category3 = dataAnalytics.categoryName;
                analytics.price = $(this).find('span.sales').attr('content') || $(this).find('span.sales .value').attr('content');
                $(this).data('list-type', analytics.list);
            }
            items.push(analytics);
        }

        var currentGtmDataClick = {
            event: 'select_item',
            ecommerce: {
                items: items
            }
        };

        window.dataLayer.push(currentGtmDataClick);
    });


    $('body').on('click', '.checkout-btn', function (e) {
        e.preventDefault();

        var $checkoutBtn = $(this);
        var gtminfourl = $checkoutBtn.data('gtminfo');
        $.ajax({
            url: gtminfourl,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                window.dataLayer.push(JSON.parse(data[0]));
            },
            complete: function () {
                window.location.href = $checkoutBtn.attr('href');
            }
        });
    });


    // $('body').on('click', '.submit-customer', function () {
    //     var checkoutAsGuestData = {
    //         event: 'eec.checkout_option',
    //         ecommerce: {
    //             checkout: {
    //                 actionField: {
    //                     step: 2,
    //                     option: 'Guest'
    //                 }
    //             }
    //         }
    //     };
    //     window.dataLayer.push(checkoutAsGuestData);
    // });


    // $('body').on('click', '.oauth-google', function () {
    //     var checkoutGoogleData = {
    //         event: 'eec.checkout_option',
    //         ecommerce: {
    //             checkout: {
    //                 actionField: {
    //                     step: 2,
    //                     option: 'Google'
    //                 }
    //             }
    //         }
    //     };
    //     window.dataLayer.push(checkoutGoogleData);
    // });


    // $('body').on('click', '.oauth-facebook', function () {
    //     var checkoutFacebookData = {
    //         event: 'eec.checkout_option',
    //         ecommerce: {
    //             checkout: {
    //                 actionField: {
    //                     step: 2,
    //                     option: 'Facebook'
    //                 }
    //             }
    //         }
    //     };
    //     window.dataLayer.push(checkoutFacebookData);
    // });


    // $('body').on('click', '.checkout-register', function () {
    //     var checkoutRegisterData = {
    //         event: 'eec.checkout_option',
    //         ecommerce: {
    //             checkout: {
    //                 actionField: {
    //                     step: 2,
    //                     option: 'Register'
    //                 }
    //             }
    //         }
    //     };

    //     window.dataLayer.push(checkoutRegisterData);
    // });

    // $('body').on('click', '.submit-customer-login', function () {
    //     var checkoutLoginData = {
    //         event: 'eec.checkout_option',
    //         ecommerce: {
    //             checkout: {
    //                 actionField: {
    //                     step: 2,
    //                     option: 'Login'
    //                 }
    //             }
    //         }
    //     };

    //     window.dataLayer.push(checkoutLoginData);
    // });

    $('body').on('login:register:success', function (e, data) {
        if (data.analytics) {
            window.dataLayer.push(data.analytics);
        }
    });

    $('body').on('login:success', function (e, data) {
        if (data.analytics) {
            window.dataLayer.push(data.analytics);
        }
    });

    $('body').on('newsletter:success', function (e, data) {
        if (data.analytics) {
            window.dataLayer.push(data.analytics);
        }
    });

    $('body').on('contactUs:success', function (e, data) {
        if (data.analytics) {
            window.dataLayer.push(data.analytics);
        }
    });

    $('body').one('click', '#buttonLiveChat1', function () {
        var url = $(this).data('gtm-url');

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (data.analytics) {
                    window.dataLayer.push(data.analytics);
                }
            }
        });
    });

    $('body').on('click', '.js-gtm-store-check', function () {
        var url = $(this).data('gtm-url');

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (data.analytics) {
                    window.dataLayer.push(data.analytics);
                }
            }
        });
    });
};
